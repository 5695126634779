//https://swiperjs.com/
import Swiper from 'swiper';
import {Navigation, Pagination, EffectFade, A11y, FreeMode,Thumbs} from 'swiper/modules';

//tabbed logo bar - scroll active tab into view
let activeTabLogo = document.querySelector('.tabbed-logo-bar li.active');
if( !!activeTabLogo ){

    document.querySelector('.tabbed-logo-bar').scrollLeft = activeTabLogo.offsetLeft;

}

//slide in to show
var slideShow = function (elem) {

	// Get the natural height of the element
    // Even though we’re showing and then re-hiding our content, it never shows visibly for users because 
    // the function runs so quickly (just a few milliseconds) and thus never actually renders visually in the DOM.
	var getHeight = function () {
		elem.style.display = 'block'; // Make it visible
        console.log(elem.scrollHeight);
		var height = elem.scrollHeight + 'px'; // Get it's height
		elem.style.display = ''; //  Hide it again
		return height;
	};

	var height = getHeight(); // Get the natural height
	elem.classList.add('is-visible'); // Make the element visible
	elem.style.height = height; // Update the max-height
    elem.style.overflow = 'hidden';

    // Once the transition is complete, remove the inline max-height so the content can scale responsively
	window.setTimeout(function () {
		elem.style.height = '';
        elem.style.overflow = '';
	}, 300);

};

//slide out to hide
var slideHide = function (elem) {

	// Give the element a height to change from
	elem.style.height = elem.scrollHeight + 'px';
    elem.style.overflow = 'hidden';

	// Set the height back to 0
	window.setTimeout(function () {
		elem.style.height = '0';
	}, 1);

	// When the transition is complete, hide it
	window.setTimeout(function () {
		elem.classList.remove('is-visible');
        elem.style.height = '';
        elem.style.overflow = '';
	}, 300);

};

// Toggle sliding element visibility
var toggle = function (elem, timing) {

	// If the element is visible, hide it
	if (elem.classList.contains('is-visible')) {
		slideHide(elem);
		return;
	}

	// Otherwise, show it
	slideShow(elem);
	
};

/*
* CLICK EVENTS
*/
document.addEventListener('click', function (event) {

	// If the clicked element is for toggling the mobile menu
	if (event.target.matches('.js-toggle-menu')){
        event.preventDefault();

        event.target.classList.toggle('menu-btn--active');
        document.querySelector('body').classList.toggle('menu-open');
    }

    // If the clicked element is for toggling responsive subnav
    if (event.target.matches('.js-subnav-expander')){
        // Don't follow the link
        event.preventDefault();

        var currentLi = event.target.parentNode;
        var subnavElement  = currentLi.querySelector(':scope > .main-nav__second');

        if( currentLi.classList.contains('toggle') ){
            
            currentLi.classList.remove('toggle');
            slideHide(subnavElement);

        } else {
            currentLi.classList.add('toggle');
            slideShow(subnavElement);
        }
    }

    //TOGGLE FILTER BAR
    if(event.target.matches('.js-toggle-filter-bar')){
        // Don't follow the link
        event.preventDefault();

        let filterBody = document.querySelector('.filter-bar-toggles');
        toggle(filterBody);
        event.target.classList.toggle('is-open');

    }

    //MultiSelect - dropdown with checkboxes
    if(event.target.matches('.multi-select__button')){
        event.preventDefault();

        //show dropdown with checkboxes
        if( event.target.closest('.multi-select').classList.contains("open") ){
			event.target.closest('.multi-select').classList.remove('open');
		}
		else{
			//close all dropdowns
			document.querySelectorAll('.multi-select').forEach(function(elem){
                elem.classList.remove('open');
            });
			//open this one
			event.target.closest('.multi-select').classList.add('open');
		}
    }

    //IF there is a mult-select
    if( document.querySelector('.multi-select') ){
        //clicking outside of the element will close any multiselects
        if( !document.querySelector('.multi-select').contains(event.target) ) {
            document.querySelectorAll('.multi-select').forEach(function(elem){
                elem.classList.remove('open');
            });
        }
    }
    

    //MAP LIST - EXPAND/COLLAPSE
    let btnExpand = document.querySelector('.js-expand-map');
    let btnShrink = document.querySelector('.js-shrink-map');
    let mapListGrid = document.querySelector('.map-list-grid');

    //EXPAND THE MAP - hide expand button, show shrink button
    if(event.target.matches('.js-expand-map')){
        event.target.classList.add('hidden');
        btnShrink.classList.remove('hidden');
        mapListGrid.classList.add('map-only');
    }
    //SHRINK THE MAP - hide shrink button, show the expand button
    if(event.target.matches('.js-shrink-map')){
        event.target.classList.add('hidden');
        btnExpand.classList.remove('hidden');
        mapListGrid.classList.remove('map-only');
    }

}, false); //end click events


/* 
* SWIPER SLIDESHOWS
*/

//COUNTDOWN SLIDER
const countdownSlider = document.querySelectorAll('.pattern--countdown-slider');
countdownSlider.forEach((slideshow, index) => {

    let slider = slideshow.querySelector('.js-countdown-slideshow');
    let pagination = slideshow.querySelector('.swiper-pagination');
    let prev = slideshow.querySelector('.swiper-nav-prev');
    let next = slideshow.querySelector('.swiper-nav-next');

    //TESTIMONIAL SLIDESHOW
    const countdownSwiper = new Swiper(slider, {
        modules: [EffectFade,Pagination,Navigation,A11y],
        loop: true,
        spaceBetween: 0,
        navigation: {
            nextEl: next,
            prevEl: prev,
        },
        pagination: {
            el: pagination,
            type: 'bullets',
            hideOnclick: false,
            clickable: true
        }
    });

});

// HERO DETAILS GALLERY w/ THUMBNAILS
const heroDetails = document.querySelectorAll('.pattern--hero-details');

heroDetails.forEach((gallery, index) => {

    let slider = gallery.querySelector('.js-hero-details-gallery');
    let thumbs = gallery.querySelector('.js-hero-details-thumbs');
    let prev = gallery.querySelector('.swiper-nav-prev');
    let next = gallery.querySelector('.swiper-nav-next');

    const galleryThumbs = new Swiper(thumbs, {
        modules: [FreeMode,A11y],
        spaceBetween: 17,
        slidesPerView: 5,
        watchSlidesProgress: true,
        freeMode: true
    });

    const galleryTop = new Swiper(slider, {
        modules: [EffectFade,Navigation,Pagination,A11y,Thumbs],
        spaceBetween: 0,
        fadeEffect: { crossFade: true },
        effect: 'fade',
        navigation: {
            prevEl: prev,
            nextEl: next
        },
        thumbs: {
            swiper: galleryThumbs,
        },
    });

});

//CAROUSEL CARDS
const cardsCarousels = document.querySelectorAll('.pattern--carousel-cards');

cardsCarousels.forEach((carousel, index) => {

    let slider = carousel.querySelector('.js-carousel-slideshow');
    let prev = carousel.querySelector('.swiper-nav-prev');
    let next = carousel.querySelector('.swiper-nav-next');
    let pagination = carousel.querySelector('.swiper-pagination');

    const carouselCards = new Swiper(slider, {
        modules: [Pagination,Navigation,A11y,FreeMode],
        slidesPerView: 1,
        spaceBetween: 24,
        centerInsufficientSlides: false,
        freeMode: true,
        watchSlidesProgress: true,
        navigation: {
            prevEl: prev,
            nextEl: next
        },
        pagination: {
            el: pagination,
            type: 'bullets',
            hideOnclick: false,
            clickable: true
        },
        breakpoints: {
            650: {
                slidesPerView: 2,
                spaceBetween: 24,
            },
            900: {
                slidesPerView: 3,
                spaceBetween: 40,
            },
            1300: {
                slidesPerView: 4,
                spaceBetween: 40,
            }

        }
    });
});


//LOGO CAROUSEL
const logoSwipers = document.querySelectorAll('.pattern--logo-carousel');

logoSwipers.forEach((logos, index) => {

    let logoSlider = logos.querySelector('.js-logo-swiper');
    let logoPag = logos.querySelector('.logo-swiper__pagination');
    let prev = logos.querySelector('.swiper-nav-prev');
    let next = logos.querySelector('.swiper-nav-next');

    const awardsList = new Swiper(logoSlider, {
        modules: [Navigation,Pagination,A11y,FreeMode],
        slidesPerView: 2,
        spaceBetween: 20,
        centerInsufficientSlides: true,
        freeMode: true,
        watchSlidesProgress: true,
        navigation: {
            prevEl: prev,
            nextEl: next
        },
        pagination: {
            el: logoPag,
            clickable: true
        },
        breakpoints: {
            650: {
                slidesPerView: 3,
                spaceBetween: 30,
            },
            768: {
                slidesPerView: 4,
                spaceBetween: 40,
            },
            1024: {
                slidesPerView: 5,
                spaceBetween: 50,
            }

        }
    });
});


// IMAGE GALLERY w/ THUMBNAILS
const galleries = document.querySelectorAll('.gallery');

galleries.forEach((gallery, index) => {

    let slider = gallery.querySelector('.js-gallery-slides');
    let galleryPrev = gallery.querySelector('.swiper-nav-prev');
    let galleryNext = gallery.querySelector('.swiper-nav-next');
    let thumbs = gallery.querySelector('.js-gallery-thumbs');

    const galleryThumbs = new Swiper(thumbs, {
        modules: [FreeMode,A11y],
        spaceBetween: 8,
        slidesPerView: 5,
        loop: false,
        freeMode: true,
        breakpoints: {
            531: {
                spaceBetween: 16,
            }
        }
    });

    const galleryTop = new Swiper(slider, {
        modules: [EffectFade,Navigation,Pagination,A11y,Thumbs],
        loop: false,
        fadeEffect: { crossFade: true },
        effect: 'fade',
        navigation: {
            prevEl: galleryPrev,
            nextEl: galleryNext
        },
        thumbs: {
            swiper: galleryThumbs,
        },
    });

});
